.price-input{
  text-align:center;
  width:25%;
  margin:auto;
}
.alert-style{
  margin-top:20px;
  margin-bottom:20px;
  border:2px solid black;
}


#delivery-box{
  display:none;
  margin-top:20px;
  margin-bottom:10px;
}

#buttonYes {
  padding: 10px;
  padding: 10px;
}

#buttonNo{
  padding: 10px;
  padding: 10px;
}
