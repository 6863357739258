/*!
 * Start Bootstrap - Full Slider (https://startbootstrap.com/template-overviews/full-slider)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-full-slider/blob/master/LICENSE)
 */


 .SliderControl{
   position:relative;
   height: 100vh;
   padding: 0px;
   top:0px;
 }

.carousel-item{
  height: 100vh;
  min-height: 100px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (min-width: 851px ){
  .slider1{
    background-image: url('/img/slider_1.jpg');
  }
  .slider2{
    background-image: url('/img/slider_2.jpg');
  }
  .slider3{
    background-image: url('/img/slider_6.jpg');
  }
}
@media screen and (max-width: 850px){
  .slider1{
    background-image: url('/img/slider_3.jpg');
  }
  .slider2{
    background-image: url('/img/slider_4.jpg');
  }
  .slider3{
    background-image: url('/img/slider_5.jpg');
  }
}
