.App{
  width:100%;
  padding: 0px;
}

.all{
  width: 100%;
}

body{
  width: 100%;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.fcolor{
  color: #008aff;
}

h3{
  color: #4fbcfb;
}
h4{
  color: #006097;
}

.serviceIcon{
  margin-right: 5px;
}
.serviceTitle{
    font-size: 1.5em;
    font-weight: bold;
    background-color: #006097;
    color: white;
}
.faqQ{
  color: #4fbcfb;
  font-size:1.3em;
}
.faqAnswer{
  margin-top: 5px;
}

.footer{
  text-align:center;
  padding: 5px;
  color: white;
  background-color: #444444;
  opacity: .85;
  position: fixed;
  bottom: 0px;
  width: 100%;
  font-size: 1.2em;
  z-index: 100;
}

section::before {
  display: block;
  content: " ";
  margin-top: 0px;
  height: 90px;
  visibility: hidden;
  pointer-events: none;
}
section::after {
  display: block;
  content: " ";
  margin-top: 0px;
  height: 90px;
  visibility: hidden;
  pointer-events: none;
}

.compTop{
  font-size:auto;
	padding:3px;
	color:black;
  font-weight: bold;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.lowCell{
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.highCell{
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.noTop{
  background-color:black;
  padding:3px;
  border-top-left-radius: 5px;
}
.lowTop{
  padding: 3px;
  color:white;
  background-color:black;
}

.compRow{
  background-color:white;
}
.compRow:nth-child(odd){
  background-color:#ddd;
}
.highTop{
  padding:3px;
  color: white;
  background-color:black;
  border-top-right-radius: 5px;
}

.compBot{
  font-size:auto;
	padding:3px;
	border:2px solid black;
	border-top:none;
}


#about, #faq{
  color:white;
  background:
  radial-gradient(black 15%, transparent 16%) 0 0,
  radial-gradient(black 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828;
  background-size:16px 16px;
}

.section section{
  padding: 0px;
  margin-top: 0px;
  top: 0px;
}

#services, #contact, #paynow{
  color:black;
  background:
  radial-gradient(#ccc 15%, transparent 16%) 0 0,
  radial-gradient(#ccc 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(0,0,0,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(0,0,0,.1) 15%, transparent 20%) 8px 9px;
  background-color:#dadada;
  background-size:16px 16px;
}


#contact{
  background-color: #dee3e4;
}

.fcolor:hover{
  color: #006097;
}

.footer {
  position: fixed;
  bottom: 0px;
  opacity: .85;
}

.turnDeviceNotification {
  background-image:url('/img/rotateDevice.png');
  background-size:cover;
  position:fixed;
  top: 0;
  left:0;
  height:100%;
  width:100%;
  display: none;
  z-index:10000000;
}

.nav-item{
  text-align:center;
}

input{
  border-radius: 6px;
}

label{
  font-weight:bold;
}

fieldset,img {
        border:0;

}

.stepButton{
  margin-left:20px;
  font-size:.8em;
  padding:15px;
  border-bottom: 2px solid black;
}

.priceGage{
  display:none;
  position: absolute;
  bottom:0px;
  left: 0px;
  opacity: .8;
  border-radius: 0px 0px 5px 5px;
  background-color: blue;
  color:white;
  text-align: center;
  padding: 10px;
  height:40px;
}

address,caption,cite,code,dfn,em,strong,th,var {
        font-style:normal;
        font-weight:normal;
}

#news{
  position:absolute;
  z-index:50;
  width:75%;
  left:16%;
  height:auto;
  padding-bottom: 10px;
  top:100px;
  border:4px solid white;
  background-color:black;
  color:white;
  border-radius:15px;
}
#newstext{
  padding:10px;
  padding-top:20px;
  font-size:1.1em;
  width:100%;
  text-align:center;
}

#darkside {
  width:100%;
  position:absolute;
  z-index:40;
  left:0px;
  top:0px;
  height:100%;
  background-color:black;
  opacity: 0.6;
      filter: alpha(opacity=60); /* For IE8 and earlier */
}

#closeBut{
  width:100%;
  margin-top:10px;
  text-align:center;
}

#allAlert{
  display: none;
}

#titleBar{
  padding-top:10px;
  border-top-right-radius:15px;
  border-top-left-radius:15px;
  width:100%;
  height:40px;
  font-weight:bold;
  font-size:25px;
  text-align:center;
  background: rgb(30,87,153); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(41,137,216,1) 50%, rgba(32,124,202,1) 51%, rgba(125,185,232,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(50%,rgba(41,137,216,1)), color-stop(51%,rgba(32,124,202,1)), color-stop(100%,rgba(125,185,232,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
}

/* Body classes */
a:link {color:#1ab8fa;font-weight:bold;cursor:pointer;border:none;}      /* unvisited link */
a:visited {color:#1ab8fa;cursor:pointer;text-decoration:none;border:none;}  /* visited link */
a:hover {color:#1ab8fa;cursor:pointer;text-decoration:none;border:none;}  /* mouse over link */
a:active {color:#1ab8fa;cursor:pointer;text-decoration:none;border:none;}  /* selected link */

i{border:none;}
i:hover {color:black;border:none;}
