.navbar-header{
    opacity: .85;
    position: fixed;
    top:0px;
    background-color: #444;
    width: 100%;
    height: 90px;
    z-index:99;
}

.logo-img{
    height: 45px;
}

.navbar .navbar-nav>li>a:hover {
    background-color: green;
  }

.nav-button{
    text-align: center;
    padding: 10px;
}
.right{
    float: right;
}

.nav-link-color{
  color: #ccc !important;
}

.nav-link:hover{
  color: #fff !important;
}


.allNav{
    position: fixed;
    background-color: #444;
    opacity: .85;
    top: 0px;
    width:100%;
    height: 90px;
    z-index:100;
}
