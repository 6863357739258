.bounding-box {
  width: 95%;
  margin: auto;
}

.width-100{
  width:100%;
}

.hosted-pay-button {
  cursor: pointer;
  background-color:#006097;
  color: white;
  font-size:1.6em;
  font-family:verdana;
  padding:5px 10px;
  display:inline-block;
  border:none;
  border-radius:7px;
  margin-top:20px;
}

.george-image{
  border-radius: 10px;
  margin-top:20px;
}

.about-div{
  max-width:600px;
  min-width:300px;
  width:75%;
  margin:auto;
  margin-top:20px;
  text-align:justify;
}

.email-us{
  font-size:.7em;
  margin-top:5px;
}

.center{
  text-align:center;
}

.left {
  text-align: left;
}

.font14{
  font-size:1.4em;
}

.font-small{
  font-size: 10px;
}

.div-spacing-10{
  margin-top:10px;
  margin-bottom:10px;
}

.div-spacing-top-30{
  margin-top:30px;
}

.div-spacing-top-20{
  margin-top:20px;
}

#deliveryBox{
  display:none;
  margin-top:20px;
  margin-bottom:10px;
}

#processing{
  font-size:10px;
  color:#333;
  text-decoration: italic;
  display:none;
}

.logo {
  height: 45px;
}

.nowrap{
  white-space:nowrap;
}

.relative{
  position: relative
}
